const town = [
  {
    "name": "Gerudo Town",
    "coordinates": [33.055, -140.59]
  },
  {
    "name": "Goron City",
    "coordinates": [78.855, -39.29]
  },
  {
    "name": "Hateno Village",
    "coordinates": [44.555, -4.39]
  },
  {
    "name": "Kakariko Village",
    "coordinates": [57.255, -36.09]
  },
  {
    "name": "Korok Forest",
    "coordinates": [77.855, -62.09]
  },
  {
    "name": "Lurelin Village",
    "coordinates": [24.555, -16.09]
  },
  {
    "name": "Rito Village",
    "coordinates": [76.255, -136.59]
  },
  {
    "name": "Tarrey Town",
    "coordinates": [75.455, 2.59]
  },
  {
    "name": "Zora's Domain",
    "coordinates": [68.655, -10.59]
  }
];

export default town;
