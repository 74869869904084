import { FloatButton, Layout } from 'antd';
import Comment from 'components/comment';
import moment from 'moment';
import { Outlet } from 'react-router-dom';

import '../style.scss';
import './style.scss';

export default function PublicLayout(props: any) {
  return <Layout className="fc-layout">
    <Layout.Header className="fc-header">
      <a className="logo" href="/">风车工具库</a>
    </Layout.Header>
    <Layout className="fc-content">
      <Layout.Content>
        <Outlet />
        {props.showComment && <Comment />}
        <FloatButton.BackTop />
      </Layout.Content>
    </Layout>
    <Layout.Footer className="fc-footer">
      <p>
        <a href='https://beian.miit.gov.cn/'>京ICP备14044390号-2</a>
      </p>
      <p>所有工具计算结果仅供参考，本网站将尽力对这些工具进行优化，但不保证计算结果的准确性及可靠性，本站对计算结果不承担任何责任。</p>
      CopyRight © 2022-{moment().year()} 风车工具库 All Rights Reserved
    </Layout.Footer>
  </Layout>;
}
