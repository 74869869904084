import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import httpClient from "utils/httpClient";

export interface GithubStarsState {
  status: string;
  items: [];
}

const initialState: GithubStarsState = {
  status: "idle",
  items: [],
};

export const getGithubStars = createAsyncThunk('github-stars/getGithubStars', async () => {
  const { data: result } = await httpClient.get('/api/v1/contents/github-stars');

  return result.items;
});

export const githubStarsSlice = createSlice({
  name: "github-stars",
  initialState,
  reducers: {

  },
  extraReducers: (builder) => {
    builder
      .addCase(getGithubStars.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getGithubStars.fulfilled, (state, action) => {
        state.status = 'idle';
        state.items = action.payload;
      })
      .addCase(getGithubStars.rejected, (state) => {
        state.status = 'failed';
      });
  },
});

export default githubStarsSlice.reducer;
