const shrine = [
    {
      "name": "Bosh Kala Shrine",
      "coordinates": [50.45750402042058, -68.29101562500001]
    },
    {
      "name": "Chaas Qeta Shrine",
      "coordinates": [31.952162238024975, 3.251953125]
    },
    {
      "name": "Dow Na'eh Shrine",
      "coordinates": [54.30370443989811, -21.29150390625]
    },
    {
      "name": "Ha Dahamar Shrine",
      "coordinates": [46.95026224218562, -39.63867187500001]
    },
    {
      "name": "Hia Miu Shrine",
      "coordinates": [82.77643252585757, -151.69921875000003]
    },
    {
      "name": "Hila Rao Shrine",
      "coordinates": [58.790978406215565, -54.44824218750001]
    },
    {
      "name": "Jitan Sa'mi Shrine",
      "coordinates": [53.87844040332883, 1.2304687500000002]
    },
    {
      "name": "Kam Urog Shrine",
      "coordinates": [51.754240074033525, -24.147949218750004]
    },
    {
      "name": "Lakna Rokee Shrine",
      "coordinates": [57.58655886615978, -33.00292968750001]
    },
    {
      "name": "Mezza Lo Shrine",
      "coordinates": [62.935234870604724, -23.378906250000004]
    },
    {
      "name": "Myahm Agana Shrine",
      "coordinates": [43.32517767999296, -8.195800781250002]
    },
    {
      "name": "Ree Dahee Shrine",
      "coordinates": [47.931066347509784, -47.109375]
    },
    {
      "name": "Shee Vaneer Shrine",
      "coordinates": [46.7549166192819, -47.4169921875]
    },
    {
      "name": "Shee Venath Shrine",
      "coordinates": [47.81315451752768, -47.68066406250001]
    },
    {
      "name": "Ta'loh Naeg Shrine",
      "coordinates": [58.228596132481464, -36.05712890625001]
    },
    {
      "name": "Tahno O'ah Shrine",
      "coordinates": [50.10648772767332, 6.416015625000001]
    },
    {
      "name": "To Quomo Shrine",
      "coordinates": [82.51774853061983, -143.83300781250003]
    },
    {
      "name": "Toto Sah Shrine",
      "coordinates": [39.791654835253425, -36.21093750000001]
    },
    {
      "name": "Ne'ez Yohma Shrine",
      "coordinates": [69.3570863282203, -9.492187500000002]
    },
    {
      "name": "Rucco Maag Shrine",
      "coordinates": [62.72453320538486, -9.140625000000002]
    },
    {
      "name": "Soh Kofi Shrine",
      "coordinates": [67.95814786101579, -29.025878906250004]
    },
    {
      "name": "Kaya Wan Shrine",
      "coordinates": [64.47279382008166, -55.107421875]
    },
    {
      "name": "Kah Mael Shrine",
      "coordinates": [73.92855278552236, 15.974121093750002]
    },
    {
      "name": "Sheh Rata Shrine",
      "coordinates": [68.54431504077692, -42.49511718750001]
    },
    {
      "name": "Daka Tuss Shrine",
      "coordinates": [62.17575962070838, -40.67138671875001]
    },
    {
      "name": "Dagah Keek Shrine",
      "coordinates": [68.88727366584355, -12.54638671875]
    },
    {
      "name": "Shai Yota Shrine",
      "coordinates": [63.470144746565445, 7.360839843750001]
    },
    {
      "name": "Qukah Nata Shrine",
      "coordinates": [27.21555620902969, -33.35449218750001]
    },
    {
      "name": "Yah Rin Shrine",
      "coordinates": [27.547241546253268, -19.094238281250004]
    },
    {
      "name": "Shoda Sah Shrine",
      "coordinates": [31.89621446335144, -37.39746093750001]
    },
    {
      "name": "Kah Yah Shrine",
      "coordinates": [26.194876675795218, -6.789550781250001]
    },
    {
      "name": "Tawa Jinn Shrine",
      "coordinates": [34.30714385628804, -21.840820312500004]
    },
    {
      "name": "Korgu Chideh Shrine",
      "coordinates": [19.02057711096681, 16.655273437500004]
    },
    {
      "name": "Muwo Jeem Shrine",
      "coordinates": [26.843677401113002, -3.2299804687500004]
    },
    {
      "name": "Shai Utoh Shrine",
      "coordinates": [21.922663209325922, -40.20996093750001]
    },
    {
      "name": "Shae Katha Shrine",
      "coordinates": [41.68932225997044, -54.25048828125001]
    },
    {
      "name": "Ka'o Makagh Shrine",
      "coordinates": [23.56398712845123, -60.05126953125001]
    },
    {
      "name": "Shoqa Tatone Shrine",
      "coordinates": [18.25021997706561, -68.26904296875001]
    },
    {
      "name": "Ya Naga Shrine",
      "coordinates": [37.78808138412046, -76.20117187500001]
    },
    {
      "name": "Pumaag Nitae Shrine",
      "coordinates": [32.80574473290688, -59.98535156250001]
    },
    {
      "name": "Ishto Soh Shrine",
      "coordinates": [22.998851594142923, -87.93457031250001]
    },
    {
      "name": "Jee Noh Shrine",
      "coordinates": [40.413496049701955, -102.78808593750001]
    },
    {
      "name": "Kay Noh Shrine",
      "coordinates": [42.00032514831623, -120.67382812500001]
    },
    {
      "name": "Dako Tah Shrine",
      "coordinates": [44.02442151965934, -131.04492187500003]
    },
    {
      "name": "Suma Sahma Shrine",
      "coordinates": [22.674847351188536, -94.9658203125]
    },
    {
      "name": "Daqo Chisay Shrine",
      "coordinates": [34.813803317113155, -139.52636718750003]
    },
    {
      "name": "Kema Zoos Shrine",
      "coordinates": [46.164614496897094, -155.43457031250003]
    },
    {
      "name": "Raqa Zunzo Shrine",
      "coordinates": [29.916852233070173, -139.70214843750003]
    },
    {
      "name": "Hawa Koth Shrine",
      "coordinates": [18.97902595325528, -158.77441406250003]
    },
    {
      "name": "Dila Maag Shrine",
      "coordinates": [24.32707654001865, -102.83203125]
    },
    {
      "name": "Tho Kayu Shrine",
      "coordinates": [35.35321610123823, -156.75292968750003]
    },
    {
      "name": "Korsh O'hu Shrine",
      "coordinates": [34.63320791137959, -119.09179687500001]
    },
    {
      "name": "Misae Suma Shrine",
      "coordinates": [19.394067895396613, -125.63964843750001]
    },
    {
      "name": "Kuh Takkar Shrine",
      "coordinates": [55.07836723201515, -126.56250000000001]
    },
    {
      "name": "Kema Kosassa Shrine",
      "coordinates": [58.147518599073585, -155.25878906250003]
    },
    {
      "name": "Sho Dantu Shrine",
      "coordinates": [50.54136296522163, -141.50390625000003]
    },
    {
      "name": "Keeha Yoog Shrine",
      "coordinates": [60.326947742998414, -140.88867187500003]
    },
    {
      "name": "Sasa Kai Shrine",
      "coordinates": [46.164614496897094, -135.79101562500003]
    },
    {
      "name": "Joloo Nah Shrine",
      "coordinates": [49.95121990866204, -106.83105468750001]
    },
    {
      "name": "Rota Ooh Shrine",
      "coordinates": [54.265224078605684, -98.70117187500001]
    },
    {
      "name": "Kaam Ya'tak Shrine",
      "coordinates": [59.712097173322924, -88.02246093750001]
    },
    {
      "name": "Noya Neha Shrine",
      "coordinates": [70.24460360904779, -87.5390625]
    },
    {
      "name": "Wahgo Katta Shrine",
      "coordinates": [56.29215668507645, -64.07226562500001]
    },
    {
      "name": "Dah Kaso Shrine",
      "coordinates": [49.095452162534826, -101.33789062500001]
    },
    {
      "name": "Saas Ko'sah Shrine",
      "coordinates": [73.28367443307855, -72.70751953125001]
    },
    {
      "name": "Namika Ozz Shrine",
      "coordinates": [71.335983102213, -56.20605468750001]
    },
    {
      "name": "Katah Chuki Shrine",
      "coordinates": [68.366801093914, -81.78222656250001]
    },
    {
      "name": "Toh Yahsa Shrine",
      "coordinates": [71.82883956585103, -111.70898437500001]
    },
    {
      "name": "Zalta Wa Shrine",
      "coordinates": [70.19999407534664, -96.50390625000001]
    },
    {
      "name": "Maag No'rah Shrine",
      "coordinates": [74.75274618925877, -104.45800781250001]
    },
    {
      "name": "Sheem Dagoze Shrine",
      "coordinates": [65.18303007291382, -104.80957031250001]
    },
    {
      "name": "Mogg Latan Shrine",
      "coordinates": [62.51231793838694, -112.14843750000001]
    },
    {
      "name": "Mijah Rokee Shrine",
      "coordinates": [64.47279382008166, -120.19042968750001]
    },
    {
      "name": "Shae Loya Shrine",
      "coordinates": [69.06856318696033, -123.35449218750001]
    },
    {
      "name": "Akh Va'quot Shrine",
      "coordinates": [76.11662168423263, -137.02148437500003]
    },
    {
      "name": "Bareeda Naag Shrine",
      "coordinates": [75.06734898853098, -136.16455078125003]
    },
    {
      "name": "Sha Warvo Shrine",
      "coordinates": [78.14352561094758, -139.17480468750003]
    },
    {
      "name": "Voo Lota Shrine",
      "coordinates": [76.10079606754579, -143.17382812500003]
    },
    {
      "name": "Tena Ko'sah Shrine",
      "coordinates": [69.1312712296365, -134.03320312500003]
    },
    {
      "name": "Kah Okeo Shrine",
      "coordinates": [68.95839084822079, -145.61279296875003]
    },
    {
      "name": "Mozo Shenno Shrine",
      "coordinates": [80.71109268759263, -135.85693359375003]
    },
    {
      "name": "Shada Naw Shrine",
      "coordinates": [81.31827658122012, -125.04638671875001]
    },
    {
      "name": "Goma Asaagh Shrine",
      "coordinates": [80.32381348490055, -121.13525390625001]
    },
    {
      "name": "Rok Uwog Shrine",
      "coordinates": [81.23496560531309, -112.56591796875001]
    },
    {
      "name": "Sha Gehma Shrine",
      "coordinates": [82.65384311083862, -100.74462890625001]
    },
    {
      "name": "Qaza Tokki Shrine",
      "coordinates": [82.10632229183118, -85.25390625000001]
    },
    {
      "name": "Maka Rah Shrine",
      "coordinates": [79.10508621944109, -144.03076171875003]
    },
    {
      "name": "Lanno Kooh Shrine",
      "coordinates": [77.39430022245843, -118.21289062500001]
    },
    {
      "name": "Dunba Taag Shrine",
      "coordinates": [75.26982671630053, -121.8603515625]
    },
    {
      "name": "Gee Ha'rah Shrine",
      "coordinates": [78.15255126151743, -114.12597656250001]
    },
    {
      "name": "Rin Oyaa Shrine",
      "coordinates": [79.2535856715539, -101.88720703125001]
    },
    {
      "name": "Mirro Shaz Shrine",
      "coordinates": [73.50346063726599, -47.1533203125]
    },
    {
      "name": "Kuhn Sidajj Shrine",
      "coordinates": [76.80073870685207, -69.03808593750001]
    },
    {
      "name": "Daag Chokah Shrine",
      "coordinates": [79.01334043073517, -71.23535156250001]
    },
    {
      "name": "Keto Wawai Shrine",
      "coordinates": [81.0932138526084, -64.99511718750001]
    },
    {
      "name": "Rona Kachta Shrine",
      "coordinates": [79.52864723963516, -89.912109375]
    },
    {
      "name": "Monya Toma Shrine",
      "coordinates": [74.83343569581847, -96.85546875000001]
    },
    {
      "name": "Keo Ruug Shrine",
      "coordinates": [77.70223385727982, -60.64453125000001]
    },
    {
      "name": "Maag Halan Shrine",
      "coordinates": [78.61266542765814, -55.45898437500001]
    },
    {
      "name": "Qua Raym Shrine",
      "coordinates": [75.06168594936098, -36.69433593750001]
    },
    {
      "name": "Tah Muhl Shrine",
      "coordinates": [72.10094360009953, -27.685546875000004]
    },
    {
      "name": "Kayra Mah Shrine",
      "coordinates": [77.55157239371562, -31.904296875000004]
    },
    {
      "name": "Daqa Koh Shrine",
      "coordinates": [78.45982190791324, -32.56347656250001]
    },
    {
      "name": "Gorae Torr Shrine",
      "coordinates": [81.89845141173647, -21.313476562500004]
    },
    {
      "name": "Shora Hah Shrine",
      "coordinates": [80.95955211381771, -41.83593750000001]
    },
    {
      "name": "Shae Mo'sah Shrine",
      "coordinates": [79.22075960626343, -37.94677734375001]
    },
    {
      "name": "Mo'a Keet Shrine",
      "coordinates": [73.30893615253117, -20.104980468750004]
    },
    {
      "name": "Sah Dahaj Shrine",
      "coordinates": [75.32559250700751, -21.335449218750004]
    },
    {
      "name": "Dah Hesho Shrine",
      "coordinates": [74.06786624952264, 0.9228515625000001]
    },
    {
      "name": "Katosa Aug Shrine",
      "coordinates": [79.76555994876071, 8.393554687500002]
    },
    {
      "name": "Tutsuwa Nima Shrine",
      "coordinates": [79.67143789507548, -1.2304687500000002]
    },
    {
      "name": "Ze Kasho Shrine",
      "coordinates": [75.66131823764809, -14.501953125000002]
    },
    {
      "name": "Zuna Kai Shrine",
      "coordinates": [81.8300417529174, -9.272460937500002]
    },
    {
      "name": "Tu Ka'loh Shrine",
      "coordinates": [82.53489651501958, 15.117187500000002]
    },
    {
      "name": "Ritaag Zumo Shrine",
      "coordinates": [77.67412223173523, 12.7880859375]
    },
    {
      "name": "Ke'nai Shakah Shrine",
      "coordinates": [71.58053179556504, 6.679687500000001]
    },
    {
      "name": "Oman Au Shrine",
      "coordinates": [51.781435604431195, -82.50732421875001]
    },
    {
      "name": "Ja Baij Shrine",
      "coordinates": [46.01222384063236, -78.13476562500001]
    },
    {
      "name": "Owa Daim Shrine",
      "coordinates": [41.82045509614034, -87.18750000000001]
    },
    {
      "name": "Keh Namut Shrine",
      "coordinates": [46.22545288226939, -96.064453125]
    }
  ];

  export default shrine;
  