import _ from 'lodash';
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface FantasyOnlineState {
  status: string;
  tasks: [];
}

const initialState: FantasyOnlineState = {
  status: "idle",
  tasks: [],
};

function getUserTasks() {
  const userTasks = JSON.parse(localStorage.getItem('game-user-tasks') || '[]')
  return userTasks;
}

function setUserTasks(userTasks: any[]) {
  localStorage.setItem('game-user-tasks', JSON.stringify(userTasks))
}

export const getTasks = createAsyncThunk('fantasy-online/getTasks', async () => {
  const response = await fetch('/api/v1/games/1/tasks?size=0');
  const result = await response.json();
  for (const item of result.items) {
    const userTasks = getUserTasks();
    const userTask = userTasks?.find((ut: any) => ut.task_id === item.id)
    item.task_status = userTask ? userTask.task_status : 0;
  }
  return result.items;
});

export const fantasyOnlineSlice = createSlice({
  name: "fantasy-online",
  initialState,
  reducers: {
    setTask: (state: any, action: PayloadAction<any>) => {
      const userTasks = getUserTasks();
      let userTask = userTasks?.find((ut: any) => ut.task_id === action.payload.id)
      if (userTask) {
        userTask.updated_at = new Date();
        userTask.task_status = action.payload.task_status;
        setUserTasks(userTasks)
      } else {
        userTask = {
          task_id: action.payload.id,
          task_status: action.payload.task_status,
          created_at: new Date(),
          updated_at: new Date()
        }
        setUserTasks((userTasks || []).concat(userTask))
      }

      const task = state.tasks.find((t: any) => t.id === action.payload.id);
      _.merge(task, action.payload)
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTasks.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getTasks.fulfilled, (state, action) => {
        state.status = 'idle';
        state.tasks = action.payload;
      })
      .addCase(getTasks.rejected, (state) => {
        state.status = 'failed';
      });
  },
});

export const { setTask } = fantasyOnlineSlice.actions;
export default fantasyOnlineSlice.reducer;
