import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import httpClient from "utils/httpClient";

export interface NewsState {
  status: string;
  items: [];
}

const initialState: NewsState = {
  status: "idle",
  items: [],
};

export const getNews = createAsyncThunk('news/getNews', async () => {
  const { data: result } = await httpClient.get('/api/v1/contents/news');

  return result.items;
});

export const newsSlice = createSlice({
  name: "news",
  initialState,
  reducers: {

  },
  extraReducers: (builder) => {
    builder
      .addCase(getNews.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getNews.fulfilled, (state, action) => {
        state.status = 'idle';
        state.items = action.payload;
      })
      .addCase(getNews.rejected, (state) => {
        state.status = 'failed';
      });
  },
});

export default newsSlice.reducer;
