const tower = [
  {
    "name": "Akkala Tower",
    "coordinates": [74.947, -9.667]
  },
  {
    "name": "Central Tower",
    "coordinates": [62.36999628130772, -84.5947265625]
  },
  {
    "name": "Dueling Peaks Tower",
    "coordinates": [49.38237278700955, -51.52587890625001]
  },
  {
    "name": "Eldin Tower",
    "coordinates": [75.314, -30.388]
  },
  {
    "name": "Faron Tower",
    "coordinates": [27.586197857692664, -45.94482421875]
  },
  {
    "name": "Gerudo Tower",
    "coordinates": [47.754097979680026, -137.46093750000003]
  },
  {
    "name": "Great Plateau Tower",
    "coordinates": [49.724479188712984, -80.26611328125]
  },
  {
    "name": "Hateno Tower",
    "coordinates": [44.19795903948531, -20.039062500000004]
  },
  {
    "name": "Hebra Tower",
    "coordinates": [77.273, -109.995]
  },
  {
    "name": "Lake Tower",
    "coordinates": [32.45415593941475, -70.86181640625001]
  },
  {
    "name": "Lanayru Tower",
    "coordinates": [66.63555577803264, -28.718261718750004]
  },
  {
    "name": "Ridgeland Tower",
    "coordinates": [71.095425047661, -102.39257812500001]
  },
  {
    "name": "Tabantha Tower",
    "coordinates": [72.3625, -136.340625]
  },
  {
    "name": "Wasteland Tower",
    "coordinates": [39.977120098439634, -112.41210937500001]
  },
  {
    "name": "Woodland Tower",
    "coordinates": [75.425, -54.03]
  }
];

export default tower;
