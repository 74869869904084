import { lazy } from 'react';

const tools = {
  Base64Route: lazy(() => import("./base64")),
  BreathOfTheWildMapRoute: lazy(() => import("./breath-of-the-wild-map")),
  CliTLDRRoute: lazy(() => import("./cli-tldr")),
  CronExpressionRoute: lazy(() => import("./cron-expression")),
  EncryptRoute: lazy(() => import("./encrypt")),
  FantasyOnlineRoute: lazy(() => import("./fantasy-online")),
  JsonFormatterRoute: lazy(() => import("./json-formatter")),
  MortgageCalculatorRoute: lazy(() => import("./mortgage-calculator")),
  RegexRoute: lazy(() => import("./regex")),
  TimestampRoute: lazy(() => import("./timestamp")),
  NewsRoute: lazy(() => import("./news")),
  WeatherRoute: lazy(() => import("./weather")),
  GithubStarsRoute: lazy(() => import("./github-stars")),
}

export default tools;