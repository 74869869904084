import { Action, configureStore, ThunkAction } from "@reduxjs/toolkit";
import breathOfTheWildMapReducer from "./slices/breath-of-the-wild-map";
import fantasyOnlineReducer from "./slices/fantasy-online";
import newsReducer from "./slices/news";
import githubStarsReducer from "./slices/github-stars";

export const store = configureStore({
  reducer: {
    breathOfTheWildMap: breathOfTheWildMapReducer,
    fantasyOnline: fantasyOnlineReducer,
    news: newsReducer,
    githubStars: githubStarsReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
