import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import locationData from "components/breath-of-the-wild-map/data/locations";

export interface BreathOfTheWildMapState {
  activeIconTypes: [];
  locations: any;
}

const initialState: BreathOfTheWildMapState = {
  activeIconTypes: [],
  locations: locationData,
};

export const breathOfTheWildMapSlice = createSlice({
  name: "breath-of-the-wild-map",
  initialState,
  reducers: {
    setActiveIconTypes: (state: any, action: PayloadAction<any>) => {
      const iconType = action.payload;
      const isAlreadyActive = state.activeIconTypes.includes(iconType);
      let activeIconTypes;

      if (isAlreadyActive) {
        activeIconTypes = state.activeIconTypes.filter((icon: any) => icon !== iconType);
      } else {
        activeIconTypes = state.activeIconTypes.concat(iconType);
      }

      state.activeIconTypes = activeIconTypes;
    },
  },
});

export const { setActiveIconTypes } = breathOfTheWildMapSlice.actions;
export default breathOfTheWildMapSlice.reducer;
