import axios, { AxiosRequestConfig } from "axios";
import _ from 'lodash';

const host = "https://fengche.tech";

export default class HttpClient {
  public static get(url: string, config?: AxiosRequestConfig) {
    const token = localStorage.getItem("token") || "";
    if (token) {
      config = _.merge(config, { headers: { Authorization: token } });
    }

    return axios.get(new URL(url, host).href, config);
  }

  public static head(url: string, config?: AxiosRequestConfig) {
    const token = localStorage.getItem("token") || "";
    if (token) {
      config = _.merge(config, { headers: { Authorization: token } });
    }

    return axios.head(new URL(url, host).href, config);
  }

  public static post(url: string, data?: any, config?: AxiosRequestConfig) {
    const token = localStorage.getItem("token") || "";
    if (token) {
      config = _.merge(config, { headers: { Authorization: token } });
    }

    return axios.post(new URL(url, host).href, data, config);
  }

  public static put(url: string, data?: any, config?: AxiosRequestConfig) {
    const token = localStorage.getItem("token") || "";
    if (token) {
      config = _.merge(config, { headers: { Authorization: token } });
    }

    return axios.put(new URL(url, host).href, data, config);
  }

  public static delete(url: string, config?: AxiosRequestConfig) {
    const token = localStorage.getItem("token") || "";
    if (token) {
      config = _.merge(config, { headers: { Authorization: token } });
    }

    return axios.delete(new URL(url, host).href, config);
  }
}
