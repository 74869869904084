import { FloatButton, Button, Layout } from 'antd';
import Comment from 'components/comment';
import moment from 'moment';
import * as React from 'react';
import { Outlet } from 'react-router-dom';
import { useLocalStorage } from 'react-use';

import './style.scss';
import '../style.scss';

interface IProps {
  hideFooter?: boolean;
  showComment?: boolean;
  style?: React.CSSProperties;
}

export default function AuthLayout(props: IProps) {
  const [token] = useLocalStorage("token", "", { raw: true });
  const isAuthenticated = !!token;

  return <Layout className="fc-layout">
    <Layout.Header className="fc-header">
      <a className="logo" href="/">风车工具库</a>
      <div className="fc-account">
        {
          isAuthenticated ?
            <>
              <Button type="link" onClick={() => {
                localStorage.setItem("token", "");
                return window.location.href = "/account/login";
              }}>退出</Button>
            </>
            :
            <Button type="link" onClick={() => {
              window.location.href = "/account/login";
            }}>登录</Button>
        }
      </div>
    </Layout.Header>
    <Layout className="fc-content" style={props.style}>
      <Layout.Content>
        <Outlet />
        {props.showComment && <Comment />}
        <FloatButton.BackTop />
      </Layout.Content>
    </Layout>
    {
      props.hideFooter ? null : <Layout.Footer className="fc-footer">
        <p>
          <a href='https://beian.miit.gov.cn/'>京ICP备14044390号-2</a>
        </p>
        <p>所有工具计算结果仅供参考，本网站将尽力对这些工具进行优化，但不保证计算结果的准确性及可靠性，本站对计算结果不承担任何责任。</p>
        CopyRight © 2022-{moment().year()} 风车工具库 All Rights Reserved
      </Layout.Footer>
    }

  </Layout>;
}
