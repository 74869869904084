import './index.scss';

import { ConfigProvider } from 'antd';
import locale from 'antd/es/locale/zh_CN';
import AuthLayout from 'layouts/auth';
import PublicLayout from 'layouts/public';
import moment from 'moment';
import 'moment/locale/zh-cn';
import Loading from 'pages/loading';
import tools from 'pages/tools';
import React, { lazy, Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { store } from 'redux/store';
import reportWebVitals from './reportWebVitals';

moment.locale('zh-cn');

const HomeRoute = lazy(() => import("./pages/home"));
const LoginRoute = lazy(() => import("pages/login"));
const RegisterRoute = lazy(() => import("pages/register"));
const CategoriesRoute = lazy(() => import("pages/categories"));

createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <ConfigProvider locale={locale}>
      <Provider store={store}>
        <Router>
          <Suspense fallback={<Loading />}>
            <Routes>
              <Route path="/" element={<AuthLayout />}>
                <Route path="/" element={<HomeRoute />} />
                <Route path="/categories" element={<CategoriesRoute />} />
                <Route path="/tools/cli-tldr" element={<tools.CliTLDRRoute />} />
                <Route path="/tools/timestamp" element={<tools.TimestampRoute />} />
                <Route path="/tools/json-formatter" element={<tools.JsonFormatterRoute />} />
                <Route path="/tools/base64" element={<tools.Base64Route />} />
                <Route path="/tools/encrypt" element={<tools.EncryptRoute />} />
                <Route path="/tools/regex" element={<tools.RegexRoute />} />
                <Route path="/tools/breath-of-the-wild-map" element={<tools.BreathOfTheWildMapRoute />} />
                <Route path="/tools/fantasy-online" element={<tools.FantasyOnlineRoute />} />
                <Route path="/tools/mortgage-calculator" element={<tools.MortgageCalculatorRoute />} />
                <Route path="/tools/cron-expression" element={<tools.CronExpressionRoute />} />
                <Route path="/tools/news" element={<tools.NewsRoute />} />
                <Route path="/tools/weather" element={<tools.WeatherRoute />} />
                <Route path="/tools/github-stars" element={<tools.GithubStarsRoute />} />
              </Route>
              <Route path="/" element={<PublicLayout />}>
                <Route path="/account/login" element={<LoginRoute />} />
                <Route path="/account/register" element={<RegisterRoute />} />
              </Route>
            </Routes>
          </Suspense>
        </Router>
      </Provider>
    </ConfigProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
